<template>
    <div class="vl-main-container-contacts">
        <div :class="parameters.isSmallScreen? 'vl-contacts-full' : 'vl-contacts'">

            <span>📍&nbsp;</span>
            <span class="vl-no-wrap">{{resource.city}}</span>,
            <span class="vl-no-wrap">{{resource.street}}</span>,
            <span class="vl-no-wrap">{{resource.office}}</span>

            <hr>

            <span>☎&nbsp;</span>
            <span class="vl-no-wrap vl-tel">636-535</span>,
            <span class="vl-no-wrap vl-tel">72-58-37</span>

            <hr>

            <table>
                <tbody>
<!--
                <tr>
                    <td>{{resource.monday_friday}}&nbsp;</td>
                    <td>09:00-18:00</td>
                </tr>
                <tr>
                    <td>{{resource.saturday}}-{{resource.sunday}}&nbsp;</td>
                    <td>{{resource.output}}</td>
                </tr>
-->
                <tr>
                    <td>{{resource.monday_friday}}&nbsp;</td>
                    <td>09:00-19:00</td>
                </tr>
                <tr>
                    <td>{{resource.saturday}}&nbsp;</td>
                    <td>11:00-15:00</td>
                </tr>
                <tr>
                    <td>{{resource.sunday}}&nbsp;</td>
                    <td>{{resource.output}}</td>
                </tr>

                </tbody>
            </table>

            <hr>

            <span>
                <span>📧&nbsp;</span>
              <a :class="parameters.isSmallScreen ? 'v-text-s' : 'v-text-l'"
                 href="mailto:mail@vectorlight.ru?cc=636535@mail.ru&subject=Обратная%20связь%20Вектор-Лайт">mail@vectorlight.ru</a>
            </span>
            <hr/>
            <a target="_blank" href="http://vk.cc/aClHh8"><img src="./../../assets/svg/qr_aClHh8.svg"
                                                               alt="ВКонтакте"></a>
        </div>
        <div :class="parameters.isSmallScreen? 'vl-map-full' : 'vl-map'">
            <iframe src="https://yandex.ru/map-widget/v1/?um=constructor%3A462ddb084b095259ccf603ca85df9411a74e081bed86fec04d85b3ad7c0dace0&amp;source=constructor"
                    width="100%" height="400" frameborder="0"></iframe>
        </div>
    </div>
</template>

<script>

    export default {
        name: 'Contacts',
        props: {
            mainContext: Object,
            parameters: Object
        },
        computed: {
            resource: function () {
                return this.mainContext.getResources()
            }
        }
    }
</script>
