<template>
    <nav>
        <ul>
            <li v-for="item in this.menu.items" v-bind:key="item.id"
                @mouseover="menuOver(item.id)"
                @mouseleave="menuLeave()"
                @click="menuClick(item.id)">
                <a :class="this.parameters.selectedMenuId === item.id? 'vl-menu-active': 'vl-menu-passive'"
                   v-bind:href="this.parameters.getCurrentState().lastPath?this.parameters.getCurrentState().lastPath:item.path">{{parameters.isSmallScreen?item.smallName:item.name}}</a>
            </li>
            <hr v-bind:class="hrClassBottom"/>
        </ul>
    </nav>
</template>

<script>

    export default {
        data() {
            return {
                overId: null
            }
        },
        props: {
            mainContext: Object,
            parameters: Object
        },
        name: 'Menu',
        computed: {
            menu: function () {
                return this.mainContext.getMenuResources()
            },
            resources: function () {
                return this.mainContext.getResources()
            },
            hrClassBottom: function () {
                const id = this.overId ? this.overId : this.parameters.selectedMenuId;
                return this.getHRClass(id);
            }
        },
        methods: {
            refreshMenuTitle() {
                const ths = this
                const titleValue = this.resources.title + " - "
                this.menu.items.map(function (item) {
                    if (item.id === ths.parameters.selectedMenuId) {
                        document.title = titleValue + item.name.toLowerCase()
                    }
                });
            },
            menuClick: function (id) {
                this.parameters.changeSelectedMenuId(id)
                this.refreshMenuTitle()
            },
            getHRClass: function (id) {
                if (id === 1) {
                    return "hr_one"
                } else if (id === 2) {
                    return "hr_two"
                } else if (id === 3) {
                    return "hr_three"
                } else {
                    return "hr_four"
                }
            },
            menuOver: function (id) {
                this.overId = id
            },
            menuLeave: function () {
                this.overId = null
            }
        },
        created: function () {
            this.overId = null
            let id;
            // let component;
            this.menu.items.map(function (item) {
                if (location.hash.includes(item.path)) {
                    id = item.id
                }
            });
            if (!id) {
                id = this.menu.default.id;
            }
            this.menuClick(id)
        }
    }
</script>
