<template>
    <div id="footer">


        <slot></slot>
    </div>
</template>

<script>
    export default {
        name: 'Footer',
    }
</script>
