<template>
    <div :class="parameters.isSmallScreen?'vl-main-container-s':'vl-main-container'">
        <keep-alive>
            <Service
                    :main-context="mainContext"
                    v-if="!parameters.connectStatus&&parameters.selectedMenuId===1"
                    ref="vlService"
                    v-bind:items="parameters.getCurrentState().items"
                    v-bind:parameters="parameters"></Service>
        </keep-alive>
        <keep-alive>
            <Cartridges
                    :main-context="mainContext"
                    v-if="!parameters.connectStatus&&parameters.selectedMenuId===2"
                    ref="vlCartridge"
                    v-bind:items="parameters.getCurrentState().items"
                    v-bind:parameters="parameters"></Cartridges>
        </keep-alive>
        <keep-alive>
            <Repair
                    :main-context="mainContext"
                    v-if="parameters.selectedMenuId===3"
                    v-bind:parameters="parameters"></Repair>
        </keep-alive>
        <keep-alive>
            <Contacts
                    :main-context="mainContext"
                    v-bind:parameters="parameters"
                    v-if="parameters.selectedMenuId===4"></Contacts>
        </keep-alive>
        <span class="vl-no-connection" v-if="parameters.connectStatus">
            {{parameters.connectStatus}}
        </span>
    </div>
</template>

<script>
    import Service from './content/Service'
    import Cartridges from './content/Cartridges'
    import Repair from './content/Repair'
    import Contacts from './content/Contacts'

    export default {
        name: 'Main',
        props: {
            mainContext: Object,
            parameters: Object
        }, components: {
            Repair,
            Cartridges,
            Service,
            Contacts
        }
    }
</script>
