<template>
    <div class="vl-full-size">
    </div>
</template>

<script>

    export default {
        name: 'VLRouter',
        props: {
            mainContext: Object,
            parameters: Object
        },
        data() {
            return {
                menu: this.mainContext.getMenuResources(),
                lastLocation: String,
                prevSelectedRowId: Number,
                prevPageIndex: Number,
                prevSearchText: String
            }
        },
        emits: [
            'update:selected-menu-item-prop',
            'path-changed'
        ],
        methods: {
            moveHash() {
                const hash = location.hash.split('?')[0]

                let newHash = hash
                newHash += '?'
                newHash += 'pageId=' + this.parameters.getCurrentState().pageIndex
                newHash += '&selectedId=' + this.parameters.getCurrentState().selectedRowId
                newHash += '&searchText=' + this.parameters.getCurrentState().searchText

                location.hash = newHash
            },
            getQueryStringParameter(parameterName) {
                let result = null, tmp = [];
                const params = location.hash.split("?")[1]

                if (params) {
                    params.split("&")
                        .forEach(function (item) {
                            tmp = item.split("=")
                            if (tmp[0] === parameterName) {
                                result = decodeURIComponent(tmp[1])
                            }
                        })
                }
                return result;
            },
            getPathById(id) {
                let ths = this
                let result = null

                ths.menu.items.map(function (item) {
                    if (id === item.id) {
                        result = item.path
                    }
                })
                return result
            },
            getIdByPath(path) {
                let ths = this
                let result = null

                ths.menu.items.map(function (item) {
                    if (path === item.path) {
                        result = item.id
                    }
                })
                return result
            },
            getServicePathById(id) {
                let ths = this
                let result = null

                ths.menu.items.map(function (item) {
                    if (id === item.id) {
                        result = item.servicePath
                    }
                })
                return result
            }
        },
        mounted() {

            let ths = this
            let previousHash = location.hash ? location.hash : this.menu.default.path
            location.hash = ''
            this.lastLocation = location.toString()
            window.onhashchange = function () {

                const preHash = location.hash.split("?")[0]
                const selectedMenuPath = ths.getPathById(ths.parameters.selectedMenuId)

                const menuId = ths.getIdByPath(preHash)
                if (selectedMenuPath !== preHash) {
                    if (preHash) {
                        // change section
                        ths.parameters.changeSelectedMenuId(menuId)
                        ths.$emit('update:selected-menu-item-prop', preHash)
                    }
                }

                // do not refresh data when lastPath equals current address
                let needToRefresh = ths.parameters.getCurrentState().lastPath !== location.hash

                ths.parameters.changeLastPath(location.hash)

                if (ths.parameters.getCurrentState().items !== null
                    && ths.lastLocation === location.toString()
                    && !ths.parameters.connectStatus) {
                    return
                }
                ths.lastLocation = location.toString()

                let pageIdParam = parseInt(ths.getQueryStringParameter('pageId'))
                let selectedIdParam = parseInt(ths.getQueryStringParameter('selectedId'))
                let searchTextParam = ths.getQueryStringParameter('searchText')

                const selectedRowId = selectedIdParam ? selectedIdParam : -1
                const pageIndex = pageIdParam ? pageIdParam : 1
                const searchText = searchTextParam ? searchTextParam : ''

                // page has been opened first time or return to suspended keep-alive component
                if (!selectedIdParam && !pageIdParam && !searchTextParam) {

                    // if data is not null and nothing was searching or paging, cancel path-changed emitting
                    if (ths.parameters.getCurrentState().items !== null
                        && !ths.parameters.getCurrentState().searchText
                        && ths.parameters.getCurrentState().pageIndex === 1
                        && !ths.parameters.connectStatus) {
                        return;
                    }
                }

                if (ths.parameters.getCurrentState().items !== null
                    && ths.prevPageIndex === pageIndex
                    && ths.prevSearchText === searchText
                    && ths.prevSelectedRowId !== selectedRowId) {
                    needToRefresh = false
                }

                // changing prev
                ths.prevPageIndex = ths.parameters.getCurrentState().pageIndex
                ths.prevSearchText = ths.parameters.getCurrentState().searchText
                ths.prevSelectedRowId = ths.parameters.getCurrentState().selectedRowId

                // changing
                ths.parameters.changeSelectedRowId(selectedRowId, false)
                ths.parameters.changePageIndex(pageIndex, false)
                ths.parameters.changeSearchText(searchText, false)

                const newServicePath = ths.getServicePathById(menuId)

                // if selected row changed, do not refresh data
                if (needToRefresh
                    || ths.parameters.connectStatus
                    || ths.parameters.getCurrentState().items == null) {
                    ths.$emit('path-changed', newServicePath)
                }
            }
            // refresh data
            location.hash = previousHash
        }
    }
</script>