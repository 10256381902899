<template>
    <VLTable v-bind:items="items"
             v-bind:parameters="parameters">
        <!--header slot-->
        <template v-slot:header>
            <tr>
                <th class="filter-row"
                    @click="enableFilter">
                    <VLInput ref="vlInput"
                             v-bind:name-prop="parameters.isSmallScreen?resource.articleS:resource.article"
                             v-bind:placeholder-prop="parameters.isSmallScreen?resource.enterCartridgeArticleS:resource.enterCartridgeArticle"
                             v-bind:parameters="parameters"></VLInput>
                </th>
                <th width="20%">
                    {{parameters.isSmallScreen?resource.refillS:resource.refill}}
                </th>
                <th width="20%">
                    {{parameters.isSmallScreen?resource.chipS:resource.chip}}
                </th>
                <th width="20%">
                    {{parameters.isSmallScreen?resource.firmwareS:resource.firmware}}
                </th>
            </tr>
        </template>
        <!--content slot-->
        <template v-slot:content>
            <tr v-for="item in rowItems" @click="parameters.changeSelectedRowId(item.id)"
                :key="item.id"
                :class="{'highlight': (item.id == parameters.getCurrentState().selectedRowId)}">
                <td><span class="vl-brand-name">{{item.brandName}}</span>{{resource.whitespace}}<span
                        class="vl-no-wrap">{{item.cartridgeArticle}}</span></td>
                <td width="20%">
                    {{item.refill?item.refill+(parameters.isSmallScreen?resource.rubS:resource.rub):"-"}}
                </td>
                <td width="20%">
                    {{item.chip?item.chip+(parameters.isSmallScreen?resource.rubS:resource.rub):"-"}}
                </td>
                <td width="20%">
                    {{item.firmware?item.firmware+(parameters.isSmallScreen?resource.rubS:resource.rub):"-"}}
                </td>
            </tr>
        </template>
    </VLTable>
</template>

<script>
    import VLTable from '../base/VLTable'
    import VLInput from '../base/VLInput'

    export default {
        name: 'Service',
        props: {
            mainContext: Object,
            items: Object,
            parameters: Object,
        },
        components: {VLTable, VLInput},
        computed: {
            resource: function () {
                return this.mainContext.getResources()
            },
            rowItems() {
                return this.items ? this.items.services : null
            }
        },
        methods: {
            enableFilter() {
                this.$refs.vlInput.enableFilter()
            }
        }
    }
</script>