<template>
    <table class="sticky">
        <thead>
        <!--HEADER-->
        <slot name="header"></slot>
        </thead>
        <tbody>
        <!--CONTENT-->
        <slot name="content"></slot>
        </tbody>
    </table>
    <div v-if="items && pdxCount > 1">
            <span v-for="pdx in pdxCount" :key="pdx"
                  :class="(parameters.getCurrentState().pageIndex === pdx) ? 'vl-page-selected' : 'vl-page'"
                  @click="parameters.changePageIndex(pdx)">
                {{pdx}}
            </span>
    </div>
</template>

<script>
    export default {
        name: 'VLTable',
        props: {
            items: Object,
            parameters: Object
        },
        data() {
            return {
                searchText: null
            }
        },
        computed: {
            pdxCount() {
                let servicesCount = this.items.count
                let pageCount = parseInt(servicesCount / this.parameters.getCurrentState().pageSize)
                if (servicesCount % this.parameters.getCurrentState().pageSize > 0) {
                    pageCount += 1
                }
                return pageCount;
            }
        }
    }
</script>