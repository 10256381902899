<template>

    <div>
        <div class="vl-tags">
            <span class="vl-tag-first" :class="tagStyle('printer')" @click="choosePriceFor('printer')">{{resource.printerRepair}}</span>
            <span class='vl-last' :class="tagStyle('pc')" @click="choosePriceFor('pc')">{{resource.pcRepair}}</span>
        </div>
        <div class="vl-tag-content">
            <VLTable
                    v-if="selectedPrice === 'printer' && printerRepairHeader"
                    v-bind:items="items.printerRepair"
                    v-bind:parameters="parameters">
                <!--header slot-->
                <template v-slot:header>
                    <tr>
                        <th class="v-left">
                            {{parameters.isSmallScreen?printerRepairHeader.equipmentTypeS:printerRepairHeader.equipmentType}}
                        </th>
                        <th width="15%">
                            {{parameters.isSmallScreen?printerRepairHeader.diagnosticS:printerRepairHeader.diagnostic}}
                        </th>
                        <th width="15%">
                            {{parameters.isSmallScreen?printerRepairHeader.preventionS:printerRepairHeader.prevention}}
                        </th>
                        <th width="10%">
                            {{parameters.isSmallScreen?printerRepairHeader.minorRepairS:printerRepairHeader.minorRepair}}
                        </th>
                        <th width="10%">
                            {{parameters.isSmallScreen?printerRepairHeader.mediumRepairS:printerRepairHeader.mediumRepair}}
                        </th>
                        <th width="10%">
                            {{parameters.isSmallScreen?printerRepairHeader.majorRepairS:printerRepairHeader.majorRepair}}
                        </th>
                    </tr>
                </template>
                <!--content slot-->
                <template v-slot:content>
                    <tr v-for="item in printerRepairContent" @click="parameters.changeSelectedRowId(item.id)"
                        :key="item.id"
                        :class="{'highlight': (item.id == parameters.getCurrentState().selectedRowId)}">
                        <td class="v-left"><span>{{item.equipmentType}}</span></td>
                        <td><span>{{item.diagnostic}}</span></td>
                        <td><span>{{item.prevention}}</span></td>
                        <td><span>{{item.minorRepair}}</span></td>
                        <td><span>{{item.mediumRepair}}</span></td>
                        <td><span>{{item.majorRepair}}</span></td>
                    </tr>
                </template>
            </VLTable>
            <VLTable
                    v-if="selectedPrice === 'pc' && pcRepairHeader"
                    v-bind:items="items.pcRepair"
                    v-bind:parameters="parameters">
                <!--header slot-->
                <template v-slot:header>
                    <tr>
                        <th class="v-left">
                            {{parameters.isSmallScreen?pcRepairHeader.serviceNameS:pcRepairHeader.serviceName}}
                        </th>
                        <th width="25%">
                            {{parameters.isSmallScreen?pcRepairHeader.priceS:pcRepairHeader.price}}
                        </th>
                    </tr>
                </template>
                <!--content slot-->
                <template v-slot:content>
                    <tr v-for="item in pcRepairContent" @click="parameters.changeSelectedRowId(item.id)"
                        :key="item.id"
                        :class="{'highlight': (item.id == parameters.getCurrentState().selectedRowId)}">
                        <td class="v-left"><span>{{item.serviceName}}</span></td>
                        <td><span>{{item.price}}</span></td>
                    </tr>
                </template>
            </VLTable>
        </div>
    </div>
</template>

<script>
    import VLTable from '../base/VLTable'

    export default {
        name: 'Service',
        props: {
            mainContext: Object,
            parameters: Object,
        },
        data() {
            return {
                selectedPrice: "printer"
            }
        },
        components: {VLTable},
        computed: {
            items: function () {
                return this.mainContext.getPriceResources();
            },
            resource: function () {
                return this.mainContext.getResources()
            },
            printerRepairContent() {
                return this.items && this.items.printerRepair ? this.items.printerRepair.content : null
            },
            printerRepairHeader() {
                return this.items && this.items.printerRepair ? this.items.printerRepair.headers : null
            },
            pcRepairContent() {
                return this.items && this.items.pcRepair ? this.items.pcRepair.content : null
            },
            pcRepairHeader() {
                return this.items && this.items.pcRepair ? this.items.pcRepair.headers : null
            }
        },
        methods: {
            tagStyle(repairType) {
                if (this.selectedPrice === repairType) {
                    return "vl-tag-active"
                }
                return "vl-tag-passive"

            },
            choosePriceFor(priceFor) {
                this.selectedPrice = priceFor
                this.parameters.changeSelectedRowId(-1)
            }
        }
    }
</script>