<template>
    <Menu
            ref="vlMenu"
            :main-context="this"
            v-bind:parameters="parameters"></Menu>
    <Main
            :main-context="this"
            v-bind:parameters="parameters"></Main>
    <Footer>
        {{this.getResources().footer}}
        <span class="lang-span" @click="this.changeLang()">
            {{getResources().lang}}
        </span>
    </Footer>
    <VLRouter ref="vlRouter"
              :main-context="this"
              v-bind:parameters="parameters"
              v-on:path-changed="refresh($event)"></VLRouter>
    <VLIndicator v-if="isLoading"></VLIndicator>
</template>

<script>

    //resources
    // RU
    import resourceRU from './assets/resources/ru/vl-global-ru.json'
    import menuResourceRU from './assets/resources/ru/vl-menu-ru.json'
    import repairRU from './assets/resources/ru/vl-price-ru.json'
    // EN
    import resourceEN from './assets/resources/en/vl-global-en.json'
    import menuResourceEN from './assets/resources/en/vl-menu-en.json'
    import repairEN from './assets/resources/en/vl-price-en.json'

    import Menu from './components/menu/Menu'
    import Main from './components/Main'
    import Footer from './components/Footer'
    import VLRouter from "./components/base/VLRouter"
    import VLIndicator from "./components/content/VLIndicator"
    import axios from "axios"

    function defaultState() {
        return {
            pageIndex: 1,
            selectedRowId: -1,
            searchText: '',
            pageSize: 25,
            items: null,
            lastPath: null
        }
    }

    // export const sess = VueSession

    export default {
        props: {
            vue: String
        },
        data() {
            return {
                locale: null,
                isLoading: true,
                parameters: {
                    state: [
                        defaultState(),
                        defaultState(),
                        defaultState(),
                        defaultState()
                    ],
                    selectedMenuId: 1,
                    context: this,
                    connectStatus: null,
                    changeLastPath(value) {
                        this.getCurrentState().lastPath = value
                    },
                    getCurrentState() {
                        return this.state[this.selectedMenuId - 1]
                    },
                    changeSelectedMenuId: function (value) {
                        this.selectedMenuId = value
                    },
                    changeSearchText: function (value, move = true) {
                        if (this.getCurrentState().searchText !== value) {
                            this.getCurrentState().searchText = value
                            if (move) {
                                this.getCurrentState().pageIndex = 1
                                this.context.$refs.vlRouter.moveHash()
                            }
                        }
                    },
                    changePageIndex: function (value, move = true) {
                        if (this.getCurrentState().pageIndex !== value) {
                            this.getCurrentState().pageIndex = value
                            if (move) {
                                this.context.$refs.vlRouter.moveHash()
                            }
                        }
                    },
                    changeSelectedRowId: function (value, move = true) {
                        if (this.getCurrentState().selectedRowId !== value) {
                            this.getCurrentState().selectedRowId = value
                            if (move) {
                                this.context.$refs.vlRouter.moveHash()
                            }
                        }
                    },
                    isSmallScreen: false,
                    smallScreenLimit: 600
                },
            }
        },
        name: 'App',
        components: {
            VLIndicator,
            Main,
            Menu,
            Footer,
            VLRouter
        },
        methods: {
            changeLang() {
                if (this.locale !== 'ru-RU') {
                    this.locale = 'ru-RU'
                } else {
                    this.locale = 'en-US'
                }
                this.$session.set('locale', this.locale)
                this.$refs.vlMenu.refreshMenuTitle()
            },
            getMenuResources() {
                if (this.locale === 'en-US') {
                    return menuResourceEN
                } else {
                    return menuResourceRU
                }
            },
            getPriceResources() {
                if (this.locale === 'en-US') {
                    return repairEN
                } else {
                    return repairRU
                }
            },
            getResources() {
                if (this.locale === 'en-US') {
                    return resourceEN
                } else {
                    return resourceRU
                }
            },
            checkScreen() {
                // global font-size
                if (window.innerWidth < this.parameters.smallScreenLimit) {
                    this.parameters.isSmallScreen = true
                    document.getElementsByTagName('html').item(0).style.fontSize = '0.8rem';
                } else {
                    this.parameters.isSmallScreen = false
                    document.getElementsByTagName('html').item(0).style.fontSize = '1rem';
                }
            },
            refresh(servicePath) {
                if (this.parameters.selectedMenuId !== null) {
                    // TODO refresh based on exact component
                    if (!servicePath) {
                        this.isLoading = false
                        return
                    }

                    const url = servicePath + "?pageIndex=" + (this.parameters.getCurrentState().pageIndex - 1) + "&pageSize=" + this.parameters.getCurrentState().pageSize + "&searchText=" + this.parameters.getCurrentState().searchText
                    this.isLoading = true
                    axios.get(url)
                        .then(response => {
                            // this way the data appears
                            this.parameters.getCurrentState().items = response.data && response.data.count > 0 ? response.data : null;
                            this.parameters.connectStatus = null
                            this.isLoading = false
                        })
                        .catch(e => {
                            this.isLoading = false
                            this.parameters.connectStatus = this.getResources().connectionProblem
                            console.log(e)
                        })
                }
            },
            scrollToTop() {
                window.scrollTo(0, 0);
            }
        }, beforeMount() {
            if (!this.$session.exists()) {
                this.$session.start()
            }
            const sessionLocale = this.$session.get('locale')
            if (sessionLocale) {
                this.locale = sessionLocale
            } else {
                if (navigator.languages !== undefined) {
                    this.locale = navigator.languages[0]
                } else {
                    this.locale = navigator.language
                }
            }
        }
        , created() {
            this.checkScreen()
            window.addEventListener('resize', this.checkScreen)
        }
    }
</script>

<style lang="scss">


</style>