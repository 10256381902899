<template>
    <input
            type="text"
            @keyup.enter="disableFilter"
            @keyup.esc="disableFilter"
            ref="searchInput"
            v-if="editMode"
            v-model="computedSearchText"
            @focusout="editMode = false"
            :placeholder="[[ placeholderProp ]]"
            style="display: inline;"/>

    <span v-else>{{nameProp}}<span
            v-if="parameters.getCurrentState().searchText">({{parameters.getCurrentState().searchText}})</span>&#128270;</span>
</template>

<script>
    export default {
        name: 'VLTable',
        props: {
            parameters: Object,
            placeholderProp: String,
            nameProp: String,
        },
        data() {
            return {
                editMode: false,
            }
        },
        computed: {
            computedSearchText: {
                get: function () {
                    return this.parameters.getCurrentState().searchText
                },
                set: function (value) {
                    this.parameters.changeSearchText(value)
                }
            }
        },
        methods: {
            enableFilter() {
                this.editMode = true
                this.$nextTick(() => this.$refs.searchInput.focus())
            },
            disableFilter() {
                this.$refs.searchInput.blur()
            },
        }
    }
</script>